<template>
  <div>
    <div class="form-group">
      <el-form-item label="Placeholder">
        <el-input v-model="field.placeholder"></el-input>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name:"templates-formComponents-Placeholder",
  props: ["field"],
  data() {
    return {};
  }
};
</script>

<style lang="scss">
</style>